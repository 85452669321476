import React from "react";
import Graph from "react-graph-vis";


import './ItemMonitor.css';

export default function ItemMonitor({graph, options, event}) {
  
 
  // const events = {
  //   select: function(event) {
  //     var { nodes, edges } = event;
  //   },
  //   interaction: {
  //     hover:true
  //   }
  // };
  
  
  return (
    <div className="item-monitor">
      
      <Graph
        graph={graph}
        options={options}
        events={event}
        getNetwork={network => {
          //  if you want access to vis.js network api you can set the state in a parent component using this property
        }}
      />

    </div>
    
  );
}