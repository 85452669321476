import axios from "axios"
import { generadorModelo } from "./generadorModelo";

export const obtenerDatosApi = async (tokenFront) => {
    const datos = {
        token: '',
        moment: []
    }

    // const tokenFront = window.localStorage.getItem('token');

    console.log("token front obtener datos api ", tokenFront)
    try {
        if(!tokenFront){
            const res = await axios.get('/monitor/iniciar')
            const dataApi = res.data.elementos;
            const tokenApi = res.data.token;
            datos.moment = generadorModelo(dataApi);
            datos.token = tokenApi
            window.localStorage.setItem('token', tokenApi)
        } else {
            const resNovedad = await axios.get('/monitor/novedades/consultar/' + tokenFront)
            const dataNovedad = resNovedad.data.novedades
            if(resNovedad.data.codigo_respuesta === 1){
              const novedad = generadorModelo(dataNovedad);  
              datos.moment = novedad;
              // agregar notificacion token expiro
            }
            if(resNovedad.data.codigo_respuesta === 400){
                
                alert(resNovedad.data.mensaje_respuesta)
                
            }
        }
        return datos; 
    } catch (err) {
        console.error("rompe", err);
    }
}
