import React from 'react';
import {NavLink} from 'react-router-dom';

import { AppBar, Toolbar, Typography, Button, BottomNavigationAction, Box } from '@mui/material';

import AppsIcon from '@mui/icons-material/Apps';
import AccountCircle from '@mui/icons-material/AccountCircle'

export const NavBar = () => {

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar 
                position="static"
                color='transparent'
                >
                <Toolbar>
                    <BottomNavigationAction label="backoffice" icon={<AppsIcon/>} component={NavLink} to='/backoffice' />

                    <Typography variant="h4" component="div" color="primary" sx={{ flexGrow: 1 }}>
                        Celta Technology - Monitor
                    </Typography>
                    <Button color="inherit">
                        <AccountCircle  />
                         
                    </Button>
                </Toolbar>
            </AppBar>
        </Box>
    )
}
