import axios from 'axios';
import moment from 'moment';

import Swal from 'sweetalert2'

const mostrarAlerta = async (params) => {
    console.log(typeof(params))
    if(typeof(params) === 'string') return

    let res = await axios.get('notificaciones/item/' + params);
    
    console.log("params grafo", params)
    
    // console.log("res apiprueba", res)

    let icon = res.data.items[0]?.Estado.tag === 'OK' ? "success" : res.data.items[0]?.Estado.tag === 'ERROR' ? "error" : "warning";

    let logs = '';

    let nombre = res.data.items[0] ? res.data.items[0]?.Item.nombre : 'NombreDefault';
    // console.log("nombre", nombre)

    let tag = res.data.items[0]?.Item.tag;
    let url = res.data.items[0]?.Item.url_chequeo;
    
    res.data.items.map((e) => {
        let fecha = moment(e.fecha).format('DD/MM/YYYY HH:mm:ss')
        // let fecha = e.fecha.split(' ');
        // let hora = fecha[1];
        // let dia = fecha[0].split('-')
        let id = e.idNotification;
        let link = '<a href="/backoffice/notificaciones/' + id + '">Detalle</a>';
        // eslint-disable-next-line
        // return logs = logs + dia[2]+'/'+dia[1]+'/'+dia[0] + ' - ' + hora +  ': '  + e.Estado.tag + ' - ' + e.Item.nombre +   ' / ' + e.mensaje + ' - ' + link + '<br/>'

        return logs = logs + fecha + " - " +e.Estado.tag + ' - ' + e.Item.nombre +   ' / ' + e.mensaje + ' - ' + link + '<br/>'

    })

    // console.log(params)
    return res && Swal.fire({
        title: nombre,
        html: logs,
        icon: icon,
        showCloseButton: true,
        showDenyButton: true,
        confirmButtonText: 'Actualizar',
        denyButtonText: 'Ver',
        denyButtonColor: '#3085d6'
    }).then(async (result) => {
        if(result.isConfirmed) {
            let resCheck = await axios.get(url)
            console.log(resCheck)
            Swal.fire('Actualizado')
        } else if (result.isDenied) {
            return window.location = "/backoffice/items/" + tag;
        }
    })
    
}

export default mostrarAlerta
