import React from 'react';
import { Link } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';

import Button from '@mui/material/Button'
import { makeStyles } from '@mui/styles';

import BackOfficeMenu from './BackOfficeMenu'



const useStyles = makeStyles(theme => ( {
    drawer: {
        width: 240,
        flexShrink: 0
    },
    drawerPaper: {
        width: 240,
        backgroundColor: "#121828",
        color: "#FFFFFF"
    },
    toolbar: {
        backgroundColor: "#121828",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar
    }
}))

const Cajon = (props) => {
    const classes = useStyles();

    return (
        <Drawer
            className={classes.drawer}
            variant={props.variant}
            open={props.open}
            onClose={props.onClose ? props.onClose : null}
            classes={{
                paper: classes.drawerPaper
            }}
            anchor='left'
        >
            <div className={classes.toolbar}>
                <Button variant="contained" component={Link} to='/' color="primary" fullWidth>Monitor</Button>
            </div>
        
            <BackOfficeMenu />
           
            
        </Drawer>
        );
};

export default Cajon;