import React, {useState, useEffect} from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import axios from 'axios';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid'

import AddIcon from '@mui/icons-material/Add';

import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete'

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Loading from '../loading/Loading';
import { Button } from '@mui/material';


export const Items = () => {

    const [items, setItems] = useState();
    const [grupos, setGrupos] = useState();
    const [reload, setReload] = useState(false);

    const navigate = useNavigate()

    useEffect(() => {
        const getData = async () => {
            try {
                let dataItems = await axios.get('items/');
                // console.log(dataItems)
                dataItems = dataItems.data.items;
                // console.log("2", dataItems)
                setItems(dataItems)

                let allGrupos = await axios.get('grupos');
                allGrupos = allGrupos.data.items;

                setGrupos(allGrupos);
                setReload(true)
            } catch (error) {
                console.log(error)
            }
            
        }
        getData();
    }, [reload])

    function alertaConfirmacion () {
        let resConfirmar = window.confirm("¿ Seguro desea eliminar el item ?");
        return resConfirmar;
    }

    async function eliminarItem (e) {
        let confirmacion = alertaConfirmacion();

        if(confirmacion === true) {
            await axios.delete('items', {data: {id: e}})
            // console.log(resEliminarItem);
            alert("Item eliminado")
            setReload(false)
        }
        
        return
    }

    function nuevoItemRedirect () {
        navigate('nuevo')
    }

    return items && grupos ? (
        <>
            <Grid container justify="flex-end" margin={4} >
                
                <Fab color="primary" aria-label='add' onClick={nuevoItemRedirect} variant="extended" >
                    <AddIcon sx={{ mr: 1 }} />
                    Nuevo Item
                </Fab>
                <Typography variant='h3' align="center" sx={{ flexGrow: 1, mr: 34 }} >
                    Items
                </Typography>
                
            </Grid>
            <Divider/>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="Items">
                    <TableHead>
                    <TableRow>
                        <TableCell>Nombre</TableCell>
                        <TableCell align="center">Tag</TableCell>
                        <TableCell align="right">Estado</TableCell>
                        <TableCell align="right">Ultima Actualizacion</TableCell>
                        <TableCell align="right">Acciones</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {items.map((item) => (
                        <TableRow
                        key={item.nombre}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            {item.nombre}
                        </TableCell>
                        <TableCell align="center">
                            {item.tag}
                        </TableCell>
                        <TableCell align="right">
                            <FiberManualRecordIcon color={item.EstadoIdEstado === 1 ? "success" : item.EstadoIdEstado === 2 ? "warning" : "error"}/>
                        </TableCell>

                        <TableCell align="right">
                            02/02/2022 10:59:05
                        </TableCell>

                        <TableCell align="right">
                            <Button component={NavLink} to={`${item.tag}`}>
                                <SearchIcon/>
                            </Button>
                            <Button component={NavLink} to={`modificar/${item.tag}`}>
                                <EditIcon/>
                            </Button>
                            <Button 
                                variant="text" 
                                type='button' 
                                color="error"
                                onClick={() => eliminarItem(item.idItem)}
                                >
                                <DeleteIcon />
                            </Button>
                        </TableCell>

                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    ) : (
        <Loading />
    );
}
