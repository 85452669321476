
export const generadorModelo = (grupos) => {

    function preTitle(text) {
        const container = document.createElement("pre");
        container.innerText = text;
        return container;
      }
    
    const modelo = [];
    // console.log("im", grupos[0])
    // console.log("length grup", grupos.length)

    if(grupos.length !== 0) {

        for(let i = 0; i < grupos.length; i++) {
            // iteramos generadores de modelos por cada elemento grupo 
                // console.log("i", grupos[i].idGrupo)
                const grupo = grupos[i];
                // console.log("grupo", i)
                const id = grupo.idGrupo;
                // console.log("grupo", grupo.idGrupo)            
        
            // Separamos hijos de la respueta    
                let modelItems = grupo.Relaciones.map( (e) => {
                    return {
                                id: e.Item.idItem,
                                label: e.Item.nombre,
                                color: e.Item.Estado.color,
                                shape: "triangleDown",
                                font: { size: 20 },
                                size: e.Item.tamanio,
                                group: "hijo",
                                afecta_padre: e.afecta_padre,
                                title: preTitle("ASCII\n    art")
                            }
                })
                // console.log("modelo item", modelItems)

            // Extraemos los estados de los items que afectan al padre
                let estadosItems = modelItems.map((e) => {
                    if(e.afecta_padre === 1){
                        return e.color
                    }
                    return undefined
                })
                estadosItems = estadosItems.filter(function(e){
                    return e !== undefined && e !== '#00d471'
                  });
                // filtro error


                // 5ea3f5
                  // celeste 48bfe3 * 01bff4 * 8CBEFF
                  // azul 0a99ff
                  // verde agua 00f5d4 * 0aefff
                
                var estadoPadre = '#00d471';
                if(estadosItems.length >= 0){
                    
                    for(let ind = 0; ind <= estadosItems.length; ind++){
                        if(estadosItems[ind] === '#c70202') {
                            estadoPadre = estadosItems[ind];
                            break;
                        } else if (estadosItems[ind] === '#eb9928'){
                            estadoPadre = estadosItems[ind];
                        }
                    }
                } 

            // Separamos padres de la respuesta    
                let modelGrupos = {
                    id: 'a'+id,
                    label: grupo.nombre,
                    group: "principal",
                    color: estadoPadre
                    }
                // console.log("modelGroup", modelGrupos)
            
            // Generamos objeto modeloNodes
                let modeloNodes = [];     
                modeloNodes.push(modelGrupos)
                modelItems.map( (e) => {
                    return modeloNodes.push(e)
                })
          
        
            // Generamos modeloEdges    
                let modeloEdges = modelItems.map( (e) => {
                    return {
                        from: e.id,
                        to: 'a'+id
                    }
                })
                // console.log("modeloEdge: ", modeloEdges)
            
                let preModel = {
                    nodes: modeloNodes,
                    edges: modeloEdges
                }
        
                // console.log("pre modelo", preModel)
                modelo.push(preModel)
            }


    }


    
    // Enviamos modelo a graph
    // console.log("modelo for", modelo)
    return modelo
}
