import {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom';
import axios from 'axios';

import {
    Typography,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    Box
  } from '@mui/material';
import Loading from '../loading/Loading';


export const ItemsDetalle = () => {

    const params = useParams();
    const [itemDetalle, setItemDetalle] = useState();
    const [notificacionesItem, setNotificacionesItem] = useState()

   
    useEffect(() => {
        const getDataItemDetalle = async () => {
            try {
                let itemTagDetalle = await axios.get('items/' + params.tag);
                // console.log(itemTagDetalle)
                itemTagDetalle = itemTagDetalle.data.items;
                // console.log("2", itemTagDetalle)
                setItemDetalle(itemTagDetalle)

                let res = await axios.get('notificaciones/tag/' + params.tag + "/10")
                res = res.data.items
                setNotificacionesItem(res)
                
            } catch (error) {
                console.log(error)
            }
            
        }
        getDataItemDetalle();
    }, [params])


    return itemDetalle && notificacionesItem ? (
        <>
            <Typography variant='h3' align="center" sx={{mb:2}} >
                Detalle Item
            </Typography>

            <Card>
                <CardHeader
                title={itemDetalle.nombre}
                />
                <Divider />
                <CardContent>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                    item
                    md={6}
                    xs={12}
                    >
                    <TextField
                        fullWidth
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                        label="Nombre"
                        name="nombre"
                        defaultValue={itemDetalle.nombre}
                        variant="standard"
                    />
                    </Grid>
                    <Grid
                    item
                    md={6}
                    xs={12}
                    >
                    <TextField
                        fullWidth
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                        label="TAG"
                        name="tag"
                        defaultValue={itemDetalle.tag}
                        variant="standard"
                    />
                    </Grid>
                    <Grid
                    item
                    md={6}
                    xs={12}
                    >
                    <TextField
                        fullWidth
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                        label="Habilitado"
                        name="habilitado"
                        defaultValue={itemDetalle.habilitado === 1 ? 'SI' : 'NO'}
                        variant="standard"
                    />
                    </Grid>
                    <Grid
                    item
                    md={6}
                    xs={12}
                    >
                    <TextField
                        fullWidth
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                        label="URL Chequeo"
                        name="url_chequeo"
                        defaultValue={itemDetalle.url_chequeo}
                        variant="standard"
                    />
                    </Grid>
                    <Grid
                    item
                    md={6}
                    xs={12}
                    >
                    <TextField
                        fullWidth
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                        label="Tamaño"
                        name="tamanio"
                        defaultValue={itemDetalle.tamanio}
                        variant="standard"
                        
                    />
                    </Grid>
                    <Grid
                    item
                    md={6}
                    xs={12}
                    >
                    <TextField
                        fullWidth
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true
                        }}
                        label="Estado"
                        name="estado"
                        defaultValue={itemDetalle.EstadoIdEstado === 1 ? "ok" : itemDetalle.EstadoIdEstado === 2 ? "warning" : "error"}
                        variant="standard"
                    />
                    </Grid>
                </Grid>
                </CardContent>
            <Divider />
            <Card>
                <CardHeader 
                    subheader="Relaciones actuales"
                    sx={{ textAlign: 'left' }}
                    />
                
                {itemDetalle.Relacion.length > 0 ? 
                
                itemDetalle.Relacion.map((e,i) => {
                    return (
                        
                        <CardContent
                        key={i}
                        >
                        <Grid container >
                            <Grid>
                                <Box 
                                    sx={{
                                        alignItems: 'left',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >
                                <Typography
                                    color="textPrimary"
                                    gutterBottom
                                    variant="h6"
                                    >
                                    {e.Grupo.nombre}
                                </Typography> 
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                        
                    )
                }) 
                 : (
                    <Typography>
                        Sin relaciones
                    </Typography>
                )   
                }
                
            </Card>
            <Divider />

        </Card>
        </>
    ) : (
        <Loading/>
    )
}
